
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    source: {type: String},
  },
})

export default class PhotoViewer extends Vue {
  isLoad!: boolean;
  source!: string;
  
  data() {
    return {
      isLoad: false,
    } 
  }
}
