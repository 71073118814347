
import OnboardingLayout from '@/components/layouts/Onboarding.vue';
import Slider from '@/components/shared/Slider.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    'onboarding-layout': OnboardingLayout,
    'slider-component': Slider,
  }
})

export default class RegisterIndex extends Vue {
  slider!: [any];

  data() {
    return {
      slider: [
        { id: 1, image: require('@/assets/images/sliders/slide01.jpg')},
        { id: 2, image: require('@/assets/images/sliders/slide01.jpg') },
        { id: 3, image: require('@/assets/images/sliders/slide01.jpg') }
      ]
    }
  }
}
