
import { Options, Vue } from "vue-class-component";

@Options({
  computed: {
    sub() {
      return false;
      //return this.nav.find((route) => { return route.path == this.$route.matched[0].path }).sub;
    }
  }
})

export default class Sidebar extends Vue {
  sub!: any;
  nav!: route[]; 
  
  data() {
    return {
      nav: [
        { name: 'Dashboard', path: '/', icon: '#dashboard-icon', class: 'dashboard' },
        { name: 'Shipments', path: '/shipments', icon: '#shipments-icon', class: 'shipments' },
        { name: 'Quotes', path: '/quotes', icon: '#quotes-icon', class: 'quotes' },
        { name: 'Connections', path: '/connections', icon: '#connections-icon', class: 'connections' },
        { name: 'Documents', path: '/documents', icon: '#documents-icon', class: 'documents' },
        { name: 'Settings', path: '/settings', icon: '#settings-icon', class: 'settings', sub: 
          [
            { name: 'Profile', path: '/settings/profile', icon: '#profile-icon', class: 'settings-profile' },
            { name: 'Company', path: '/settings/companyinfo', icon: '#company-icon', class: 'settings-companyinfo' },
            { name: 'Users', path: '/settings/users', icon: '#users-icon', class: 'settings-users' },
            { name: 'Notifications', path: '/settings/notifications', icon: '#notification-icon', class: 'settings-notifications' },
            // { name: 'Localization', path: '/settings/localization', icon: '#dashboard-icon', class: 'settings-localization' },
          ]
        },
        { name: 'Support', path: '/support', icon: '#support-icon', class: 'support' },
      ]
    } 
  }
}

export interface route { name: string, path: string, icon: string, class: string, sub: route[] }
