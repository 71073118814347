
import { Options, Vue } from "vue-class-component";
import PhotoViewer from "./PhotoViever.vue";
import { User } from "@/models/commons/user";

@Options({
  components: {
    'photo-viewer': PhotoViewer
  },
  computed: {
    user(): User { return new User(this.$store.state.user) },
    companies() { return this.$store.state.companies },
    pendingCompanies() { return this.$store.state.pendingCompanies },
  },
  methods: {
    setCompany(company) {
      this.$store.dispatch('setCompanyId', company.id)
    },
    getUserPhotoPlaceholder(): string {
      if (! this.user.id) return '';
      return (this.user.first_name[0] + this.user.last_name[0]).toUpperCase();
    },
    getCompanyPhotoPlaceholder(company): string {
      if (! company.id) return '';
      return (company.name[0] + company.name[1]).toUpperCase();
    },
  }
})

export default class CompanyChooser extends Vue {
  user!: User;
  companies!: any[];
  pendingCompanies!: any[];
  setCompany!: any;
  getUserPhotoPlaceholder!: any;
  getCompanyPhotoPlaceholder!: any;

  data() {
    return {}
  }
}
