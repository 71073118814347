
import { markRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import Notification from "@/components/shared/Notification.vue";
import Profile from "@/components/shared/Profile.vue";
import AlertMessage from "./AlertMessage.vue";
import { User } from "@/models/commons/user";
import PhotoViewer from "./PhotoViever.vue";


@Options({
  components: {
    'alert-message': AlertMessage,
    'photo-viewer': PhotoViewer
  },
  computed: {
    breadcrumps() {
      return this.$route.matched
        .filter((route) => { return route.path != '/' && route.meta.title })
        .map((route) => { return { path: route.path, name: route.meta.title } })
    },
    badge() { return this.$route.meta.badge ?? null; },
    alertMessage() { return this.$store.state.alertMessage },
    user(): User { return new User(this.$store.state.user) },
  },
  methods: {
    getUserPhotoPlaceholder(): string {
      if (! this.user.id) return '';
      return (this.user.first_name[0] + this.user.last_name[0]).toUpperCase();
    },
    logout() {
      this.$router.push('/login');
      this.$store.dispatch('clearStorage');
    },
    getBadgeValue() {
      if (this.$route.path.includes('/shipments/new')) return 'New';
      return null;
    },
    toggleMenu (type: string) {
      if (!type) return;
      const menu = this.menus.find((item: menu) => item.type == type);
      if (!menu) return;
      menu.show = !menu.show;
      this.menuIsShow = !this.menuIsShow;
    }
  }
})

export default class Headbar extends Vue {
  breadcrumps!: crump[];
  badge!: string;
  logout!: any;
  getBadgeValue!: any;
  getUserPhotoPlaceholder!: any;
  alertMessage!: AlertMessage;
  user!: User;

  toggleMenu!: any;
  menus!: menu[];
  menuIsShow!: boolean;
  

  data() {
    return {
      menuIsShow: false,
      menus: [
        { type: 'notification', show: false, component: markRaw(Notification) },
        { type: 'profile', show: false, component: markRaw(Profile) }
      ],
    }
      
  }
}

export interface crump { path: string, name: string , badge: string }
export interface menu { type: string, show: boolean, component: any }
