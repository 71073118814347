
import { Options, Vue } from 'vue-class-component';
import { VariableServices } from './services/Variables';

@Options({
  computed: {
    // variableServices () { return VariableServices.getInstance() },
    // init() { return this.$store.state.init; },
    // variables() { return this.$store.state.variables; }
  },
  async created() {
    // let response = await this.variableServices.init();
    // setTimeout(() => {
    //   response.data.forEach(async (item: any) => {
    //     await this.variableServices.get(item.key);
    //     if (this.variables.length == this.init.length) {
    //       this.loaded = true;
    //       setTimeout(() => this.ready = true, 500);
    //     }
    //   });
    // }, 1750);
  },
})

export default class App extends Vue {
  loaded!: boolean;
  ready!: boolean;
  variableServices!: VariableServices;
  
  data() {
    return {
      loaded: false,
      ready: false,
    }
  }
}
