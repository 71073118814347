
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(current, old) {
      if (this.closing) return;
      if (!old && current) this.status = true;
      if (old && !current) this.close()
    }
  },
  methods: {
    close() {
      this.closing = true;
      setTimeout(() => {
        this.closing = false;
        this.status = false;
        this.$emit('update:show', false);
      }, 250);
    },
  },
})

export default class Modal extends Vue {
  status!: boolean;
  show!: boolean;
  closing!: boolean;
  close!: any;

  data() {
    return {
      status: this.show,
      closing: false
    }
  }
}

