
import { Options, Vue } from "vue-class-component";
import Headbar from "../shared/Headbar.vue";
import Sidebar from "../shared/Sidebar.vue";
import Modal from "../shared/Modal.vue";
import CompanyChooser from '../shared/CompanyChooser.vue';
import Response from '../shared/Response.vue';
import { VariableServices } from '@/services/Variables';
import { AuthServices } from "@/services/Auth";
import { AccountsServices } from "@/services/Accounts";
import { User } from "@/models/commons/user";
import { InvitationServices } from "@/services/Invitation";

@Options({
  components: {
    'sidebar-component': Sidebar,
    'headbar-component': Headbar,
    'modal-component': Modal,
    'companychooser-component': CompanyChooser,
    'response-component': Response,
  },
  props: {
    title: {type: String}
  },
  watch: {
    user(n, o) {
      if (!n && o) this.$router.push('/login');
    }
  },
  computed: {
    variableServices () { return VariableServices.getInstance() },
    init() { return this.$store.state.init; },
    variables() { return this.$store.state.variables; },
    user(): User { return new User(this.$store.state.user) },
    companies() { return this.$store.state.companies },
    company() { return this.$store.getters.company },
    pendingCompanies() { return this.$store.state.pendingCompanies },
  },
  async created() {
    if (this.$route.matched[0].path == '/invitation/:process*') {
      const process = this.$route.params.process;
      if (process[0] == 'approve' && process[1]) {
        if (this.user.is_complete) await this.invitationService.approve({ data: process[1] });
        else {
          await this.invitationService.check({ data: process[1] }).then((response: any) => {
            const invitation = response.data;
            this.$store.dispatch('setInvitation', { invitation: invitation, process: process[1] });
            if (invitation.invited) this.$router.push({ path: '/login', hash: `#!${invitation.email}` });
            else this.$router.push({ path: '/register', hash: `#!${invitation.email}` });
          }).catch((error: any) => {
            this.$store.dispatch('setAlertMessage', { type: 'error', title: 'Approve Error', messages: [ error.response.data.message ] });
          });
        }
      }
      //this.$router.push('/register');
      this.loaded = true;
      return;
    }
    
    await this.variableServices.init();
    this.init.forEach(async (item: any) => await this.variableServices.get(item.key) );
    
    await this.authServices.me().catch(error => {
      if (error.response.status == 401) {
        this.$store.dispatch('clearStorage');
        this.$router.push('/login');
      }
    });

    const invitation = this.$store.state.invitation;
    if (invitation && this.user.is_complete) {
      await this.invitationService.approve({ data: invitation.process });
      this.$store.dispatch('removeInvitation');
    }

    if (this.user.id) {
      await this.accountsServices.compaines().then((response: any) => {
        this.$store.dispatch('setCompanyRoles', response.roles);
        this.$store.dispatch('setCompanies', response.data);
        this.$store.dispatch('setPendingCompanies', response.pendings);
      });
      if (! this.user.is_complete) return this.$router.push('/register/profile');
      if (! this.companies.length && ! this.pendingCompanies.length) return this.$router.push('/register/company');
    }
    
    this.loaded = true;
    setTimeout(() => {
      this.ready = true;
      const redirectUrl = this.$store.state.redirectUrl;
      if (redirectUrl) {
        this.$store.dispatch('removeRedirectUrl');
        this.$router.push(redirectUrl);
      }
    }, 500);
    
  },
  methods: {
    getCompanyPhotoPlaceholder(company): string {
      if (! company.id) return '';
      return (company.name[0] + company.name[1]).toUpperCase();
    },
  }
})

export default class AppLayout extends Vue {
  loaded!: boolean;
  ready!: boolean;
  title!: string;
  user!: any;
  companies!: [any];
  company!: any;
  getCompanyPhotoPlaceholder!: any;
  authServices!: AuthServices;
  accountsServices!: AccountsServices;
  invitationService!: InvitationServices;
  
  data() {
    return {
      loaded: false,
      ready: false,
      authServices: AuthServices.getInstance(),
      accountsServices: AccountsServices.getInstance(),
      invitationService: InvitationServices.getInstance()
    }
  }
}
