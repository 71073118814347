
import { Options, Vue } from 'vue-class-component';

@Options({
  computed: {
    baseDomain () {
      const host = window.location.host;
      const domain = host.includes('.') ? host.split('.').slice(-2).join('.') : host;
      return domain;
    }
  }
})

export default class Landing extends Vue {
  baseDomain!: string;
}
