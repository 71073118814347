
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {},
  watch: {},
  methods: {
    close() {
      this.$emit('close');
    },
    toggleMarked(index, markedItemsArray) {
      const markedItems = this[markedItemsArray];
      
      if (markedItems.includes(index)) {
        const itemIndex = markedItems.indexOf(index);
        markedItems.splice(itemIndex, 1);
      } else {
        markedItems.push(index);
      }
    },
    markAllAsRead() {
      this.todayMarkedItems = Array.from({ length: 4 }, (_, index) => index);
      this.yesterdayMarkedItems = Array.from({ length: 2 }, (_, index) => index);
    },
    clearAll() {
      console.log("clear all");
    }
  },
})

export default class Notification extends Vue {
  tabs!: any;
  selectedTab!: any;
  todayMarkedItems!: any;
  yesterdayMarkedItems!: any;
  toggleMarked!: any;
  clearAll!: any;
  markAllAsRead!: any;
  close!: any;

  // services

  data() {
    return {
      tabs: [
        { key:"all", name: "All"},
        { key:"shipment", name: "Shipment", icon:"#shipments-icon"},
        { key:"quote_request", name: "Quote Request", icon: "#quotes-icon"},
        { key:"connection", name: "Connection", icon: "#connections-icon"},
      ],
      selectedTab: "all",
      todayMarkedItems: [],
      yesterdayMarkedItems: [],
    }
  }
}

