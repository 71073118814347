
import OnboardingLayout from '@/components/layouts/Onboarding.vue';
import Slider from '@/components/shared/Slider.vue';
import { Options, Vue } from 'vue-class-component';
import { Router, useRouter } from 'vue-router';

@Options({
  components: {
    'onboarding-layout': OnboardingLayout,
    'slider-component': Slider,
  },
  created() {
    //console.log(this.router.currentRoute);
  }
})

export default class LoginIndex extends Vue {
  slider!: [any];
  router!: Router;

  data() {
    return {
      router: useRouter(),
      slider: [
        { id: 1, title: "Did you see our new modules ?", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { id: 2, title: "Did you see our new modules ?", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { id: 3, title: "Did you see our new modules ?", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit." }
      ]
    }
  }
}
