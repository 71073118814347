
import { Options, Vue } from "vue-class-component";
import { Autoplay, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

@Options({
  components: { Swiper, SwiperSlide },
  props: {
    slider: {type: Array},
    pagerPosition: { type: String, default: 'center' },
  },
  methods: {
    onSwiper() {  
      //console.log('onSwiper')
    },
    onSlideChange() {
      //console.log('onSlideChange')
    }
  }
})

export default class Slider extends Vue {
  modules!: any;
  pagination!: any;
  autoplay!: any;
  onSwiper!: any;
  onSlideChange!: any;
  slider!: any
  pagerPosition!: string
  
  data() {
    return {
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet: (index: number, className: string) => '<span class="' + className + '"></span>'
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      modules: [Autoplay, Pagination, A11y]
    } 
  }
}
