
import { Options, Vue } from "vue-class-component";
import { uuid } from 'vue-uuid';

@Options({
  components: { uuid },
  emits: [ 'update:messages', 'close' ],
  props: {
    type: {
      type: String,
      default: 'error',
      validator: (value: string): boolean => ['info', 'success', 'error'].indexOf(value)!=-1
    },
    title: { type: String },
    more: { type: String },
    messages: { type: Array }
  },
  methods: {
    close() {
      this.$store.dispatch('removeAlertMessage');
      this.$emit('close');
      this.$emit('update:messages', null);
    }
  }
})

export default class AlertMessage extends Vue {
  type!: string;
  title!: string;
  more!: string;
  messages!: any;
  icons!: any;
  titles!: any;
  close!: any;

  data() {
    return {
      icons: { info: '#info-icon', success: '#success-icon', error: '#warning-icon' },
      titles: { info: 'Info', success: 'Success', error: 'Error' }
    }
  }
}
